import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'home'
	},
	{ 
		path: 'home', 
		loadChildren: () => import('./components/home/home.module')
			.then(m => m.HomeModule) 
	},
	{
		path: 'register',
		loadChildren: () => import('./components/register/register.module')
			.then(m => m.RegisterModule)
	},
	{
		path: 'plattech-admin',
		loadChildren: () => import('./components/admin/admin.module')
			.then(m => m.AdminModule),
			canActivate: [AuthGuard]
	},
	{
		path: 'plattech-investor',
		loadChildren: () => import('./components/investor/investor.module')
			.then(m => m.InvestorModule),
			canActivate: [AuthGuard]
	},
	{
		path: 'plattech-builder',
		loadChildren: () => import('./components/builder/builder.module')
			.then(m => m.BuilderModule),
			canActivate: [AuthGuard]
	},
	{ 
		path: 'about', 
		loadChildren: () => import('./components/about/about.module')
			.then(m => m.AboutModule) 
	},
	{
		path: 'project/:id',
		loadChildren: () => import('./components/project/project.module')
			.then(m => m.ProjectModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
