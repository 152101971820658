const url: string 	= 'https://plattech.com.co/api_token'
const token: string 	= 'fug0cXzjJo2MFoZACUHErhL?UT1ebaOBPNoVI1tJywmkf0//bxu-VkcaANqlk2azOHQ4KckSwOje9l1QysjyX!Ry?sULRAUtwza?fSUSqqYZ=r2ObIvVYnKnK6CQaKFZhoEt-Y4Iyra9NDGDcxR!yi=KmUxH6Az4IdAjHs5Bha3d6dfQSozKPIr9Fdu!rdDOShzWnlVydhBBXRzWU3Tk7mRfE-rsLOTt66z-AIE!BGhqxtqLGxzmCN-h9ysVn3uJ';
export const environment = {
	production: 	true,
	mainToken: 		`${token}`,
	personas: 		`${url}/Personas/`,
	constructores: `${url}/Constructor/`,
	maestros: 		`${url}/Maestros/`,
	proyectos: 		`${url}/Proyectos/`
};
